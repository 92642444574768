import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import { SPACING_BETWEEN_COLUMNS, SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { EuroFormat, FloatFormat, IntegerFormat } from './common/inputFormats';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE, WARNING_MESSAGE } from './common/Alert';
import Formular from './common/Formular';
import { useHistory } from 'react-router';
import { NUTZUNGSAUSFALL_KLASSE_ZU_WERT } from '../shared/domain/vorgang';
import { useUser } from '../hooks/useUser';
import { generiereToken } from '../domain/generiereToken';
import { isOlderThan } from '../shared/dateTime';
import { isAenderbar } from '../domain/isAenderbar';
import { DatResponse, Fahrzeugdaten, Vorgang } from '../types';
import * as mutations from '../graphql/mutations';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import { useSnackbar } from 'notistack';
import { YesNoFormControl } from './YesNoFormControl';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_FAHRZEUGDATEN_ERFASSEN } from '../frontendConstants';
import { Gutachtenart, Zulassungsbescheinigung } from '../shared/constants';
import { S3Client } from '../shared/s3Client';
import { CustomDatePicker } from './common/customDatePickers';
import { AlteGutachtenZuFinButton } from './AlteGutachtenZuFinButton';
import { FahrzeugAuswahlDialog } from './dialog/FahrzeugAuswahlDialog';
import { sindFahrzeugdatenVollstaendig } from '../domain/sindFahrzeugdatenVollstaendig';
import { diffErstzulassungZurBauzeitDesMarktindexGroesserEinJahr } from '../shared/util';

const ZULASSUNGSBESCHEINIGUNG_TEXTE = {
  [Zulassungsbescheinigung.ZULASSUNGSBESCHEINIGUNG_I]: 'Teil 1',
  [Zulassungsbescheinigung.ZULASSUNGSBESCHEINIGUNG_II]: 'Teil 2'
};

type FahrzeugdatenAttribut = keyof Fahrzeugdaten;
type FahrzeugdatenWert = string | boolean | null | undefined;

type Props = {
  readonly vorgang: Vorgang;
  readonly setLoading: (value: boolean) => void;
  readonly isLoading: boolean;
  readonly setzeVorgang: (vorgang: Vorgang) => void;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
  readonly speichereVorgang: () => Promise<void>;
};

export function Fahrzeugidentifikation({ vorgang, setLoading, isLoading, setzeVorgang, aktualisiereVorgang, speichereVorgang }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { gruppenVonMandant, user } = useUser();
  const history = useHistory();
  const [isSynchronizing, setIsSynchronizing] = useState(false);
  const [fahrzeugKandidaten, setFahrzeugKandidaten] = useState<string[]>([]);

  const synchronisiereMitDat = useCallback(async () => {
    try {
      setLoading(true);
      setIsSynchronizing(true);

      const datAntwort = await makeGraphqlQuery<DatResponse<Vorgang>>(mutations.synchronisiereMitDat, {
        vorgangId: vorgang.id
      });

      if (datAntwort.ok) {
        if (datAntwort.data) {
          setzeVorgang(datAntwort.data);
          enqueueSnackbar('Der Vorgang wurde erfolgreich mit DAT synchronisiert.', SUCCESS_MESSAGE_AUTO_HIDE);
        } else {
          enqueueSnackbar('Vorgang in datAntwort nicht gefunden', ERROR_MESSAGE);
        }
      } else {
        enqueueSnackbar(datAntwort.error, ERROR_MESSAGE);
      }
    } catch (error) {
      console.error({ error });
      enqueueSnackbar(String(error), ERROR_MESSAGE);
    } finally {
      setLoading(false);
      setIsSynchronizing(false);
    }
  }, [enqueueSnackbar, setLoading, setzeVorgang, vorgang.id]);

  const handleAktualisiereVehicle = useCallback(
    (fahrzeug?: string) => {
      setLoading(true);
      setFahrzeugKandidaten([]);

      return makeGraphqlQuery<DatResponse<string>>(mutations.aktualisiereVehicle, {
        vorgangId: vorgang.id,
        vehicle: fahrzeug
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.error);
          }
        })
        .then(() => synchronisiereMitDat())
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(error, ERROR_MESSAGE);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [enqueueSnackbar, setLoading, synchronisiereMitDat, vorgang.id]
  );

  useEffect(() => {
    if (isSynchronizing) {
      return;
    }

    if (history.location.search.includes('contractid')) {
      // Calculate Expert

      const params = new URLSearchParams(window.location.search);
      const contractId = params.get('contractid');
      const datECode = params.get('datecode');

      setLoading(true);
      setIsSynchronizing(true);

      makeGraphqlQuery(mutations.aktualisiereDATDaten, {
        vorgangId: vorgang.id,
        contractId,
        datECode
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.error);
          }
        })
        .then(synchronisiereMitDat)
        .catch((error) => {
          console.error({ error });
          enqueueSnackbar(error.message, ERROR_MESSAGE);
        })
        .finally(() => {
          history.replace(history.location.pathname);
          setLoading(false);
          setIsSynchronizing(false);
        });
    }
  }, [enqueueSnackbar, history, history.location.search, isSynchronizing, setLoading, synchronisiereMitDat, vorgang.id]);

  const handleCalculateExpertClick = async () => {
    try {
      setLoading(true);
      await speichereVorgang();
      setIsSynchronizing(true);
      const tokens = await generiereToken(vorgang.mandant);
      const contractIdParam = vorgang?.dat?.contractId ? `contractId=${vorgang.dat.contractId}` : '';
      window.location.href = `/dat-calculateExpert-fahrzeug.html?${contractIdParam}&token=${tokens.calculateExpertToken}&redirectUrl=${window.location.href}`;
    } finally {
      setLoading(false);
      setIsSynchronizing(false);
    }
  };

  const handleFahrzeugLoeschen = async () => {
    try {
      setLoading(true);
      setIsSynchronizing(true);
      const aktualisierterVorgang = await loescheDatDaten(vorgang);
      setzeVorgang(aktualisierterVorgang);
    } finally {
      setLoading(false);
      setIsSynchronizing(false);
    }
  };

  const zulassungsbescheinigungAuswahl = Object.entries(ZULASSUNGSBESCHEINIGUNG_TEXTE).map(([value, label]) => ({ label, value }));

  const isDisabled =
    !vorgang || isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_FAHRZEUGDATEN_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  const fahrzeugIdentifikationIstDeaktiviert =
    isDisabled ||
    isSynchronizing ||
    Boolean(vorgang?.fahrzeugdaten?.container && vorgang?.fahrzeugdaten?.container.length > 0) ||
    Boolean(vorgang?.dat?.contractId && !vorgang?.fahrzeugdaten?.containerName && vorgang?.dat?.datECode);
  const phantomKalkulationIstDeaktiviert = isDisabled || isSynchronizing || !vorgang?.fahrzeugdaten?.fahrgestellnummer || !vorgang?.dat?.contractId;

  const handleDatIdentifcationClick = async () => {
    try {
      if (!sindFahrzeugdatenVollstaendig(vorgang)) {
        enqueueSnackbar('Der Vorgang hat keine Fahrzeugdaten.', WARNING_MESSAGE);
        return;
      }

      setLoading(true);
      await speichereVorgang();

      const datAntwort = await makeGraphqlQuery<DatResponse<string[]>>(mutations.identifiziereFahrzeug, {
        vorgangsnummer: vorgang.vorgangsnummer,
        fahrgestellnummer: vorgang.fahrzeugdaten?.fahrgestellnummer,
        erstesZulassungsdatum: vorgang.fahrzeugdaten?.erstesZulassungsdatum,
        mandant: vorgang.mandant
      });

      if (!datAntwort.ok) {
        enqueueSnackbar(datAntwort.error, ERROR_MESSAGE);
        setLoading(false);
        return;
      }

      const fahrzeugKandidaten = datAntwort?.data ?? [];
      if (fahrzeugKandidaten.length === 1) {
        const vehicleJsonString = datAntwort.data?.[0] ?? '';
        await handleAktualisiereVehicle(vehicleJsonString);
      } else if (fahrzeugKandidaten.length > 1) {
        setFahrzeugKandidaten(fahrzeugKandidaten);
      } else {
        // Synchronisiere mt DAT, damit ein Phantom benutzt werden kann
        await handleAktualisiereVehicle();
      }
    } catch (error) {
      console.error({ error });
      enqueueSnackbar(error, ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  function istScheckheftRelevant(): boolean | undefined {
    if (vorgang.fahrzeugdaten?.erstesZulassungsdatum) {
      return isOlderThan(vorgang.fahrzeugdaten.erstesZulassungsdatum, 3, 'years');
    }
    return undefined;
  }

  const aktualisiereFahrzeugdaten = (attribut: FahrzeugdatenAttribut, wert: FahrzeugdatenWert) => {
    aktualisiereVorgang({
      fahrzeugdaten: {
        ...vorgang.fahrzeugdaten,
        [attribut]: wert
      }
    });
  };

  return (
    <>
      {fahrzeugKandidaten.length > 1 && (
        <FahrzeugAuswahlDialog
          fahrzeugKandidaten={fahrzeugKandidaten}
          onClose={() => setFahrzeugKandidaten([])}
          onSelect={handleAktualisiereVehicle}
          open={fahrzeugKandidaten.length > 1}
        />
      )}
      <Formular ueberschrift="Fahrzeugidentifikation">
        <Grid container spacing={SPACING_BETWEEN_COLUMNS}>
          <Grid item container xs={12}>
            <Grid item container spacing={SPACING_BETWEEN_FORM_FIELDS}>
              <Grid container item xs={12} sm={6}>
                <Grid item xs={12} sm={7}>
                  <FahrgestellnummerField
                    vorgang={vorgang}
                    isDisabled={isDisabled}
                    handleFahrzeugLoeschen={handleFahrzeugLoeschen}
                    aktualisiereFahrzeugdaten={aktualisiereFahrzeugdaten}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  sx={(theme) => ({
                    [theme.breakpoints.up('md')]: {
                      display: 'flex'
                    }
                  })}
                >
                  <AlteGutachtenZuFinButton vorgangId={vorgang?.id} fahrgestellnummer={vorgang?.fahrzeugdaten?.fahrgestellnummer} username={user?.username} />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Zulassungsbescheinigung</InputLabel>
                  <Select
                    variant="standard"
                    value={vorgang?.fahrzeugdaten?.zulassungsbescheinigung ?? ''}
                    onChange={(event) => {
                      aktualisiereFahrzeugdaten('zulassungsbescheinigung', event.target.value);
                    }}
                    data-testid="fahrzeugdaten-zulassungsbescheinigung"
                    disabled={isDisabled}
                  >
                    {zulassungsbescheinigungAuswahl.map((auswahl) => (
                      <MenuItem key={auswahl.value} value={auswahl.value}>
                        {auswahl.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="standard"
                  label="Marktindex"
                  value={vorgang?.fahrzeugdaten?.containerName ?? ''}
                  disabled={true}
                  onChange={() => undefined}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="standard"
                  label="Kilometerleistung (abgelesen)"
                  error={(vorgang.fahrzeugdaten?.kilometerleistung?.length ?? 0) > 7}
                  value={vorgang.fahrzeugdaten?.kilometerleistung ?? ''}
                  disabled={isDisabled}
                  onChange={(event) => {
                    aktualisiereFahrzeugdaten('kilometerleistung', event.target.value);
                  }}
                  onBlur={() => {
                    if (!vorgang.fahrzeugdaten?.kilometerleistungGeschaetzt) {
                      aktualisiereFahrzeugdaten('kilometerleistungGeschaetzt', vorgang.fahrzeugdaten?.kilometerleistung);
                    }
                  }}
                  fullWidth
                  data-testid="fahrzeugdaten-kilometerleistung"
                  InputProps={{
                    inputComponent: FloatFormat
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="standard"
                  label="Kilometerleistung (unterstellt/geschätzt)"
                  error={(vorgang.fahrzeugdaten?.kilometerleistungGeschaetzt?.length ?? 0) > 7}
                  value={vorgang.fahrzeugdaten?.kilometerleistungGeschaetzt ?? ''}
                  disabled={isDisabled}
                  onChange={(event) => {
                    aktualisiereFahrzeugdaten('kilometerleistungGeschaetzt', event.target.value || '');
                  }}
                  fullWidth
                  data-testid="fahrzeugdaten-kilometerleistungGeschaetzt"
                  InputProps={{
                    inputComponent: FloatFormat
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="standard"
                  label="Kilometerleistung (angegeben)"
                  error={(vorgang.fahrzeugdaten?.kilometerleistungAngegeben?.length ?? 0) > 7}
                  value={vorgang.fahrzeugdaten?.kilometerleistungAngegeben ?? ''}
                  disabled={isDisabled}
                  onChange={(event) => aktualisiereFahrzeugdaten('kilometerleistungAngegeben', event.target.value || '')}
                  fullWidth
                  data-testid="fahrzeugdaten-kilometerleistungAngegeben"
                  InputProps={{
                    inputComponent: FloatFormat
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  disabled={isDisabled}
                  defaultValue={vorgang?.fahrzeugdaten?.land ?? ''}
                  variant="standard"
                  label="Land"
                  fullWidth
                  data-testid="fahrzeugdaten-land"
                  inputProps={{
                    style: { textTransform: 'uppercase' }
                  }}
                  onChange={(event: React.FocusEvent<HTMLInputElement>) => {
                    aktualisiereFahrzeugdaten('land', event.target.value.toUpperCase());
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="standard"
                  label="Kennzeichen"
                  defaultValue={vorgang?.fahrzeugdaten?.kennzeichen ?? ''}
                  disabled={isDisabled}
                  inputProps={{
                    style: { textTransform: 'uppercase' }
                  }}
                  onChange={(event) => {
                    aktualisiereFahrzeugdaten('kennzeichen', event.target.value.toUpperCase());
                  }}
                  fullWidth
                  data-testid="fahrzeugdaten-kennzeichen"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CustomDatePicker
                  openTo="year"
                  views={['year', 'month', 'day']}
                  label="Erstzulassungsdatum"
                  value={vorgang.fahrzeugdaten?.erstesZulassungsdatum}
                  disabled={
                    isDisabled ||
                    Boolean(vorgang?.dat?.datECode) ||
                    (vorgang?.gutachtenart === Gutachtenart.REPARATURNACHWEIS &&
                      (vorgang?.fahrzeugdaten?.fahrgestellnummer ?? '').length > 0 &&
                      (vorgang?.fahrzeugdaten?.erstesZulassungsdatum ?? '').length > 0)
                  }
                  onDateSelect={(date) => aktualisiereFahrzeugdaten('erstesZulassungsdatum', date)}
                  fullWidth={true}
                  data-testid="fahrzeugdaten-erstesZulassungsdatum"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CustomDatePicker
                  openTo="year"
                  views={['year', 'month', 'day']}
                  label="letztes Zulassungsdatum"
                  disableFuture
                  minDate={vorgang.fahrzeugdaten?.erstesZulassungsdatum}
                  value={vorgang.fahrzeugdaten?.letztesZulassungsdatum}
                  disabled={isDisabled}
                  onDateSelect={(date) => aktualisiereFahrzeugdaten('letztesZulassungsdatum', date)}
                  fullWidth
                  data-testid="fahrzeugdaten-letztesZulassungsdatum"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CustomDatePicker
                  openTo="year"
                  views={['year', 'month']}
                  label="Nächste HU"
                  value={vorgang.fahrzeugdaten?.naechsteHauptuntersuchung}
                  disabled={isDisabled}
                  onDateSelect={(date) => aktualisiereFahrzeugdaten('naechsteHauptuntersuchung', date)}
                  fullWidth
                  selectEndOfMonth
                  data-testid="fahrzeugdaten-naechsteHauptuntersuchung"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="standard"
                  label="Anzahl der Vorhalter"
                  value={vorgang.fahrzeugdaten?.anzahlVorhalter ?? ''}
                  disabled={isDisabled}
                  onChange={(event) => aktualisiereFahrzeugdaten('anzahlVorhalter', event.target.value || '')}
                  fullWidth
                  data-testid="fahrzeugdaten-anzahlVorhalter"
                  InputProps={{
                    inputComponent: IntegerFormat
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                {istScheckheftRelevant() && (
                  <YesNoFormControl
                    labelText="Scheckheftgepflegt"
                    value={vorgang?.fahrzeugdaten?.scheckheftgepflegt}
                    disabled={isDisabled}
                    dataTestid="fahrzeugdaten-scheckheftgepflegt"
                    onChange={(jaNein) => {
                      aktualisiereFahrzeugdaten('scheckheftgepflegt', jaNein);
                    }}
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={3}>
                <YesNoFormControl
                  labelText="Fahrzeug regelbesteuert"
                  value={vorgang?.fahrzeugdaten?.regelbesteuert}
                  disabled={isDisabled}
                  dataTestid="fahrzeugdaten-regelbesteuert"
                  onChange={(jaNein) => {
                    aktualisiereFahrzeugdaten('regelbesteuert', jaNein);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="standard"
                  label="Mietwagenklasse"
                  value={vorgang.fahrzeugdaten?.mietwagenklasse ?? ''}
                  disabled={isDisabled}
                  onChange={(event) => aktualisiereFahrzeugdaten('mietwagenklasse', event.target.value)}
                  fullWidth
                  data-testid="fahrzeugdaten-mietwagenklasse"
                  InputProps={{
                    inputComponent: IntegerFormat
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="standard"
                  label="Nutzungsausfallgruppe"
                  defaultValue={vorgang?.fahrzeugdaten?.nutzungsausfallKlasseOhneFahrzeugAlter ?? ''}
                  disabled={isDisabled}
                  onChange={(event) => {
                    aktualisiereFahrzeugdaten('nutzungsausfallKlasseOhneFahrzeugAlter', event.target.value.toUpperCase());
                  }}
                  fullWidth
                  data-testid="fahrzeugdaten-nutzungsausfallKlasse"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  variant="standard"
                  label="Entschädigung"
                  defaultValue={
                    (NUTZUNGSAUSFALL_KLASSE_ZU_WERT as Record<string, number>)[vorgang?.fahrzeugdaten?.nutzungsausfallKlasseOhneFahrzeugAlter ?? ''] ?? ''
                  }
                  disabled={true}
                  fullWidth
                  data-testid="fahrzeugdaten-nutzungsausfallWert"
                  InputProps={{
                    inputComponent: EuroFormat,
                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  fullWidth
                  disabled={fahrzeugIdentifikationIstDeaktiviert}
                  color="primary"
                  variant="contained"
                  startIcon={<FingerprintIcon />}
                  onClick={handleDatIdentifcationClick}
                >
                  Fahrzeugidentifikation
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  fullWidth
                  disabled={phantomKalkulationIstDeaktiviert}
                  color="primary"
                  variant="contained"
                  startIcon={<DriveEtaIcon />}
                  onClick={handleCalculateExpertClick}
                >
                  Phantomkalkulation
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                {!isLoading && (
                  <HinweisPhantomkalkulation
                    vorgang={vorgang}
                    fahrzeugIdentifikationIstAktiviert={!fahrzeugIdentifikationIstDeaktiviert}
                    phantomKalkulationIstAktiviert={!phantomKalkulationIstDeaktiviert}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Formular>
    </>
  );
}

type HinweisPhantomkalkulationProps = {
  readonly vorgang: Vorgang;
  readonly phantomKalkulationIstAktiviert: boolean;
  readonly fahrzeugIdentifikationIstAktiviert: boolean;
};

function HinweisPhantomkalkulation({
  vorgang,
  phantomKalkulationIstAktiviert,
  fahrzeugIdentifikationIstAktiviert
}: HinweisPhantomkalkulationProps): JSX.Element | null {
  let hinweisText = null;

  if (diffErstzulassungZurBauzeitDesMarktindexGroesserEinJahr(vorgang)) {
    hinweisText = 'Die Differenz Erstzulassung zur Bauzeit des Marktindex ist größer als 1 Jahr.';
  }

  if (vorgang?.fahrzeugdaten?.phantomkalkulation && !vorgang?.fahrzeugdaten?.containerName) {
    hinweisText = 'Der Marktindex konnte nicht ermittelt werden.';
  }

  if (phantomKalkulationIstAktiviert && fahrzeugIdentifikationIstAktiviert) {
    hinweisText = (
      <span>
        Das Fahrzeug konnte nicht identifiziert werden.
        <br /> Bitte Phantomkalkulation ausführen.
      </span>
    );
  }

  return hinweisText ? (
    <Box
      color="warning.main"
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <WarningIcon />
      <Box component="span" sx={{ padding: '0 0 0 5px' }}>
        {hinweisText}
      </Box>
    </Box>
  ) : null;
}

export async function loescheDatDaten(zuAktualisierenderVorgang: Vorgang): Promise<Vorgang> {
  if (zuAktualisierenderVorgang.dat?.calculationDocument?.key) {
    await S3Client.remove(zuAktualisierenderVorgang.dat.calculationDocument.key);
  }
  const vorgang = {
    ...zuAktualisierenderVorgang,
    fahrzeugdaten: {
      ...zuAktualisierenderVorgang.fahrzeugdaten,
      fahrgestellnummer: '',
      container: null,
      containerName: null,
      phantomkalkulation: false,
      mietwagenklasse: null,
      nutzungsausfallKlasse: null,
      nutzungsausfallKlasseOhneFahrzeugAlter: null
    },
    dat: {
      dossierId: zuAktualisierenderVorgang?.dat?.dossierId,
      contractId: zuAktualisierenderVorgang?.dat?.contractId
    }
  } as Partial<Vorgang>;

  return makeGraphqlQuery(mutations.aktualisiereVorgang, {
    vorgang: JSON.stringify(vorgang)
  });
}

function FahrgestellnummerField({
  vorgang,
  isDisabled,
  handleFahrzeugLoeschen,
  aktualisiereFahrzeugdaten
}: {
  readonly vorgang: Vorgang;
  readonly isDisabled: boolean;
  readonly handleFahrzeugLoeschen: () => Promise<void>;
  readonly aktualisiereFahrzeugdaten: (attribut: FahrzeugdatenAttribut, wert: FahrzeugdatenWert) => void;
}) {
  const [fahrgestellnummernfehler, setFahrgestellnummernfehler] = useState<string | null>(null);

  const validiereFahrgestellnummer = (value: string) => {
    const isEmpty = value === '';
    const hasCorrectLength = value.length === 17;
    const hasOnlyValidCharacters = /^[A-HJ-NPR-Z0-9]+$/.test(value);
    if (!isEmpty) {
      if (!hasOnlyValidCharacters) {
        setFahrgestellnummernfehler('Es sind nur Zahlen und Buchstaben außer I, O und Q erlaubt.');
      } else if (!hasCorrectLength) {
        setFahrgestellnummernfehler('Die Fahrgestellnummer muss 17 Zeichen haben.');
      } else {
        setFahrgestellnummernfehler(null);
      }
    } else {
      setFahrgestellnummernfehler(null);
    }
  };

  return (
    <TextField
      variant="standard"
      label="Fahrgestellnummer"
      fullWidth
      defaultValue={vorgang?.fahrzeugdaten?.fahrgestellnummer ?? ''}
      disabled={
        isDisabled ||
        Boolean(vorgang?.dat?.datECode) ||
        (vorgang?.gutachtenart === Gutachtenart.REPARATURNACHWEIS &&
          (vorgang?.fahrzeugdaten?.fahrgestellnummer ?? '')?.length > 0 &&
          !!vorgang?.zugehoerigeGutachtenId)
      }
      inputProps={{
        style: { textTransform: 'uppercase' }
      }}
      onChange={(event) => {
        validiereFahrgestellnummer(event.target.value.toUpperCase());
      }}
      onBlur={(event) => {
        const value = event.target.value.toUpperCase();
        if (value !== vorgang.fahrzeugdaten.fahrgestellnummer) {
          aktualisiereFahrzeugdaten('fahrgestellnummer', value);
        }
      }}
      error={Boolean(fahrgestellnummernfehler)}
      helperText={fahrgestellnummernfehler}
      data-testid="fahrzeugdaten-fahrgestellnummer"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={
                isDisabled ||
                (vorgang?.gutachtenart === Gutachtenart.REPARATURNACHWEIS &&
                  Boolean((vorgang?.fahrzeugdaten?.fahrgestellnummer ?? '').length > 0 && !!vorgang?.zugehoerigeGutachtenId))
              }
              onClick={handleFahrzeugLoeschen}
              data-testid="vermittler-loeschen"
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}
