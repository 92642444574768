import Box from '@mui/material/Box/Box';
import Chip from '@mui/material/Chip';
import React, { useState } from 'react';
import Formular from '../../../components/common/Formular';
import Bestaetigungsdialog from '../../../components/dialog/Bestaetigungsdialog';
import { useVorgangContext } from '../../../contexts/vorgangContext';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export function VorgangAdministrationSeite(): JSX.Element {
  const { vorgang, speichereVorgang } = useVorgangContext();
  const berechtigteBearbeiter = [...vorgang.ehemaligeBearbeiter];
  function handleDeleteBearbeiter(zuLoeschenderBearbeiter: string) {
    const neueBearbeiterListe = berechtigteBearbeiter.filter((b) => b !== zuLoeschenderBearbeiter);
    speichereVorgang({ id: vorgang.id, ehemaligeBearbeiter: neueBearbeiterListe });
  }

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [zuLoeschenderBearbeiter, setZuLoeschenderBearbeiter] = useState<string>('');

  return (
    <>
      <Toolbar>
        <Typography variant="h6">Vorgangsadministration</Typography>
      </Toolbar>
      <Formular ueberschrift="Ehemalige Bearbeiter">
        <Box>
          {berechtigteBearbeiter?.map((b) => {
            return (
              <Chip
                key={b}
                label={b}
                onDelete={() => {
                  setZuLoeschenderBearbeiter(b);
                  setOpenDeleteDialog(true);
                }}
              />
            );
          })}
        </Box>
      </Formular>
      <Bestaetigungsdialog
        title="Bearbeiter löschen"
        text={`Wollen Sie den Bearbeiter ${zuLoeschenderBearbeiter} für den Vorgang wirklich löschen?`}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={() => {
          handleDeleteBearbeiter(zuLoeschenderBearbeiter);
          setOpenDeleteDialog(false);
        }}
      />
    </>
  );
}
