import { Vorgang, Zahlungseingang } from '../types';

export function berechneGesamtzahlungsbetrag(zahlungseingang: Array<Zahlungseingang>): number {
  let summe = 0;
  for (const betrag of zahlungseingang.map((eingang) => eingang.betrag)) {
    summe += betrag;
  }
  return summe;
}

// Differenz Erstzulassung zur Bauzeit des Marktindex ist größer als 1 Jahr
export function diffErstzulassungZurBauzeitDesMarktindexGroesserEinJahr(vorgang: Vorgang): boolean {
  return vorgang?.fahrzeugdaten?.phantomkalkulation && vorgang?.fahrzeugdaten?.containerName ? true : false;
}
